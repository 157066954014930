import { mergeOverrides, useStyletron } from "baseui";
// eslint-disable-next-line no-restricted-imports
import type { FormControlProps as BaseUIFormControlProps, FormControlOverrides } from "baseui/form-control";
// eslint-disable-next-line no-restricted-imports
import { FormControl as BaseUIFormControl } from "baseui/form-control";
import type { Overrides } from "baseui/overrides";
import { merge } from "lodash";
import React from "react";

export { StyledCaption } from "baseui/form-control";
export type { FormControlOverrides } from "baseui/form-control";

export type FormControlProblemSeverity = "fatal" | "warning" | "error" | "info";

export interface FormControlProps extends BaseUIFormControlProps {
  severity?: FormControlProblemSeverity;
}

/**
 * BaseUI re-export for form controls that have a label wrapping a form element
 */
export const FormControl = (props: FormControlProps) => {
  const [css, $theme] = useStyletron();

  const overrides: FormControlOverrides = {
    Label: {
      style: {
        marginBottom: $theme.sizing.scale100,
      },
    },
    Caption: {
      style: {
        marginTop: $theme.sizing.scale100,
      },
    },
    ControlContainer: {
      style: {
        display: "flex",
        flexDirection: "column",
        marginBottom: 0,
      },
    },
  };

  if (props.severity == "warning") {
    merge(overrides, { Caption: { style: { color: $theme.colors.contentWarning } } });
  } else if (props.severity == "error" || props.severity == "fatal") {
    merge(overrides, { Caption: { style: { color: $theme.colors.contentNegative } } });
  }

  return (
    <BaseUIFormControl {...props} overrides={mergeOverrides(overrides as Overrides<any>, props.overrides as Overrides<any> | undefined)} />
  );
};
